import React from "react";
import { Layout, Space } from "antd";
const { Content } = Layout;
import { Header, InstallmentPlansForm } from "@components";
import { useParams } from "react-router-dom";

export const InstallmentPlansNew = () => {
  const { id, variant_id } = useParams();
  return (
    <Space direction="vertical" style={{ width: "100%", backgroundColor: "white" }} size={[0, 48]}>
      <Content>
        <Header selected="products" />
        <InstallmentPlansForm shopifyProductId={id} shopifyVariantId={variant_id} />
      </Content>
    </Space>
  );
};
