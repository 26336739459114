import React, { FunctionComponent, useState } from "react";
import type { MenuProps } from "antd";
import { ShopTwoTone, DollarTwoTone } from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

interface IHeaderProps {
  selected: string;
}

export const Header: FunctionComponent = ({ selected }: IHeaderProps) => {
  const navigate = useNavigate();
  const items: MenuProps["items"] = [
    {
      label: "Products",
      key: "products",
      icon: <ShopTwoTone />
    },
    {
      label: "Payment Plans",
      key: "installment-plans",
      icon: <DollarTwoTone />
    }
  ];
  const [current, setCurrent] = useState(selected || "products");

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    navigate(e.key === "installment-plans" ? "/shopify/installment-plans" : "/shopify");
  };

  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};
