import React, { useEffect, useState } from "react";
import { Layout, Button, Spin } from "antd";
const { Content } = Layout;
import { Col, Row } from "antd";
import { Alert } from "antd";
import { IInstallmentPlanType } from "@types";
import { moneyFormat, postApi } from "@utils";
import { useParams, useNavigate } from "react-router-dom";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";

interface IPaymnetFormProps {
  installmentPlan: IInstallmentPlanType;
  publicPage: boolean;
}

export const Form = ({ installmentPlan, publicPage }: IPaymnetFormProps) => {
  const { id, hash } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let paymentElement = null;
  let addressElement = null;

  useEffect(() => {
    if (elements) {
      paymentElement = elements.create("card", {
        iconStyle: "solid",
        style: {
          base: {
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased"
          }
        }
      });
      paymentElement.mount("#payment-element");
      addressElement = elements.create("address", {
        mode: "shipping",
        fields: {
          phone: "always"
        }
      });
      addressElement.mount("#address-element");
    }
  }, [elements]);
  // @ts-ignore

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    elements
      .getElement("address")
      .getValue()
      .then(function (addressResult) {
        if (addressResult.complete) {
          stripe.createToken(elements.getElement("card")).then(async (result) => {
            setLoading(true);
            const response = await postApi(
              publicPage ? "payHashInstallmentPlans" : "payInstallmentPlans",
              {
                email,
                token: result.token.id,
                address: addressResult.value
              },
              { id, hash }
            );
            setLoading(false);
            if (response.status === 201) {
              setSuccess(true);
            } else {
              setError(response.error);
            }
          });
        }
      });
  };

  return (
    <Col span={24} style={{ margin: 0 }}>
      <Row>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} span={16}>
          {loading && <Spin />}
          {error && <Alert message={error} type="error" style={{ marginBottom: 20 }} />}
          {success && (
            <Alert message="Payment successful! YP Collective will reach out to you about your order." type="success" />
          )}
          {
            <form onSubmit={handleSubmit} style={{ display: (!success && !loading) || error ? "block" : "none" }}>
              <label
                style={{
                  color: "rgb(48, 49, 61)",
                  transition:
                    "transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1)",
                  fontSize: "0.93rem",
                  marginBottom: "0.25rem"
                }}
              >
                Email
              </label>
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{
                  width: "calc(100% - 24px)",
                  padding: 12,
                  marginBottom: 10,
                  backgroundColor: "white",
                  borderRadius: 5,
                  transition: "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
                  border: "1px solid rgb(230, 230, 230)",
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)"
                }}
              />
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 25,
                  width: "calc(100% - 24px)",
                  padding: 12,
                  backgroundColor: "white",
                  borderRadius: 5,
                  transition: "background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease",
                  border: "1px solid rgb(230, 230, 230)",
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)"
                }}
              >
                <div id="payment-element" />
              </div>
              <div
                style={{
                  width: "100%"
                }}
              >
                <div id="address-element" />
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ marginTop: 20, width: "100%" }}
                  disabled={!publicPage || loading}
                >
                  Pay{" "}
                  {moneyFormat(parseFloat(installmentPlan?.deposit) + parseFloat(installmentPlan?.cancellation_fee))}{" "}
                  Today & Start Payment Plan
                </Button>
              </div>
            </form>
          }
        </Col>
        <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
      </Row>
    </Col>
  );
};
