import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, InstallmentPlansNew, InstallmentPlansIndex, PaymentFormPage, PaymentFormPublicPage } from "@components";

const router = createBrowserRouter([
  {
    path: "/shopify",
    Component: Home
  },
  {
    path: "/shopify/installment-plans/new/:id/:variant_id",
    Component: InstallmentPlansNew
  },
  {
    path: "/shopify/installment-plans",
    Component: InstallmentPlansIndex
  },
  {
    path: "/shopify/payment-form/:id",
    Component: PaymentFormPage
  }
]);

const publicRouter = createBrowserRouter([
  {
    path: "/payment-forms/:hash",
    Component: PaymentFormPublicPage
  }
]);

document.addEventListener("DOMContentLoaded", () => {
  if (!document.getElementById("shopify-app-init")) {
    return;
  }
  var data = document.getElementById("shopify-app-init").dataset;
  var AppBridge = window["app-bridge"];
  var createApp = AppBridge.default;
  // @ts-ignore
  window.app = createApp({
    apiKey: data.apiKey,
    host: data.host
  });

  var actions = AppBridge.actions;
  var TitleBar = actions.TitleBar;
  // @ts-ignore
  TitleBar.create(app, {
    title: data.page
  });
});

// @ts-ignore
window.initApp = () => {
  createRoot(document.body.appendChild(document.createElement("div"))).render(<RouterProvider router={router} />);
};

// @ts-ignore
window.initPayment = () => {
  createRoot(document.body.appendChild(document.createElement("div"))).render(<RouterProvider router={publicRouter} />);
};
