import React from "react";
import { Layout, Space } from "antd";
const { Content } = Layout;
import { Header, ProductsIndex } from "@components";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  return (
    <Space direction="vertical" style={{ width: "100%", backgroundColor: "white" }} size={[0, 48]}>
      <Content>
        <Header selected="products" />
        <ProductsIndex />
      </Content>
    </Space>
  );
};
