import React, { useEffect, useState } from "react";
import { Spin, Typography, Divider } from "antd";
const { Content } = Layout;
import { getApi, moneyFormat, postApi } from "@utils";
import { Col, Row, Alert } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { useParams, useNavigate } from "react-router-dom";
import { Form } from "./Form";
import { Elements } from "@stripe/react-stripe-js";
import { IInstallmentPlanType, IVariantType } from "@types";
import { Space, Layout } from "antd";
import pluralize from "pluralize";
import moment from "moment";
const { Title, Text } = Typography;

interface IPaymentFormProps {
  publicPage: boolean;
}

export const PaymentForm = ({ publicPage }: IPaymentFormProps) => {
  const { id, hash } = useParams();
  const [loading, setLoading] = useState(false);
  const [installmentPlan, setInstallmentPlan] = useState<IInstallmentPlanType>();
  const [shopifyVariant, setShopifyVariant] = useState<IVariantType>();

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi(
      publicPage ? "getHashedInstallmentPlans" : "getInstallmentPlanById",
      {},
      { id, hash }
    );
    setLoading(false);
    setInstallmentPlan(response.installment_plans);
    setShopifyVariant(response.shopify_variant);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // @ts-ignore
  const stripePromise = loadStripe(window.App.Flags.Client.stripe_publishable_key);
  const options = {
    // @ts-ignore
    clientSecret: window.App.Flags.Client.client_secret,
    fonts: [
      {
        cssSrc: "https://fonts.googleapis.com/css?family=Roboto"
      }
    ]
  };

  return (
    <Space
      direction="vertical"
      style={{ width: "100%", backgroundColor: "white", padding: "15px 0 60px" }}
      size={[0, 18]}
    >
      <img src="https://ypcollective.com/cdn/shop/files/yp-logo.png?v=1684231447&width=600" width="60" />
      <h1 style={{ margin: "15px 0" }}>YP Collective Payment Plan</h1>
      {loading && <Spin />}
      {!loading && installmentPlan && (
        <Content>
          <Row>
            <Col md={8} lg={8} xl={8} xs={24} sm={24}>
              <Row>
                <Col span={6}>
                  {installmentPlan.shopify_product && (
                    <img
                      src={installmentPlan.product_image}
                      width={80}
                      style={{ borderRadius: 5, border: "1px solid #eee" }}
                    />
                  )}
                </Col>
                <Col span={18}>
                  <h3 style={{ marginTop: 0 }}>
                    {installmentPlan.shopify_product && installmentPlan.shopify_product.title}
                  </h3>
                  {installmentPlan.shopify_variant && <p>{moneyFormat(installmentPlan.shopify_variant.price)}</p>}
                  {installmentPlan.shopify_variant && <p>{installmentPlan.shopify_variant.title}</p>}
                </Col>
              </Row>
              <Row>
                {
                  <Space direction="vertical" size={[0, 4]}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                      Pay{" "}
                      {moneyFormat(parseFloat(installmentPlan.deposit) + parseFloat(installmentPlan.cancellation_fee))}{" "}
                      Today.
                    </Title>
                    {parseFloat(installmentPlan.cancellation_fee) > 0 && (
                      <Text type="secondary">
                        <span style={{ color: "red" }}>*</span> Includes initial Deposit of{" "}
                        {moneyFormat(installmentPlan.deposit)} + Cancellation Fee of{" "}
                        {moneyFormat(installmentPlan.cancellation_fee)}
                        <br />
                      </Text>
                    )}
                    <Text>
                      Then pay {moneyFormat(installmentPlan.recurring_price)} a {installmentPlan.interval_type}, for{" "}
                      {pluralize(
                        installmentPlan.interval_type,
                        installmentPlan.installment_count,
                        installmentPlan.installment_count !== 1
                      )}{" "}
                      starting {moment().add(1, installmentPlan.interval_type).format("MMMM Do YYYY")}.
                    </Text>
                    <Alert
                      style={{ margin: "15px 0" }}
                      type="info"
                      message={
                        <>
                          Upon completing the payment plan, YP Collective will fulfill your order.
                          {parseFloat(installmentPlan.cancellation_fee) > 0 && (
                            <>
                              <br />
                              You will receive an order confirmation and will be refunded the initial Cancellation Fee
                              of {moneyFormat(installmentPlan.cancellation_fee)}.
                            </>
                          )}
                        </>
                      }
                    />
                  </Space>
                }
                <Col span={24}>
                  <p>
                    <strong>Payment Plan Summary</strong>
                  </p>
                  <table cellPadding="5">
                    <tbody>
                      <tr>
                        <td>Payment Plan Interval</td>
                        <td>
                          <strong>
                            Every&nbsp;
                            {pluralize(
                              installmentPlan.interval_type,
                              installmentPlan.interval_count,
                              installmentPlan.interval_count !== 1
                            )}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Installment count</td>
                        <td>
                          <strong>{installmentPlan.installment_count}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Amount Due Every&nbsp;
                          {pluralize(
                            installmentPlan.interval_type,
                            installmentPlan.interval_count,
                            installmentPlan.interval_count !== 1
                          )}
                        </td>
                        <td>
                          <strong>{moneyFormat(installmentPlan.recurring_price)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Amount Paid by End of Payment Plan</td>
                        <td>
                          <strong>{moneyFormat(installmentPlan.shopify_variant.price)}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Alert
                    style={{ margin: "15px 0" }}
                    type="warning"
                    message={
                      <>
                        If you fail to complete the full payment plan, YP Collective will cancel your order. You will be
                        refunded all of your payments
                        {parseFloat(installmentPlan.cancellation_fee) > 0 && (
                          <>
                            {" "}
                            <u>except for the Cancellation Fee of {moneyFormat(installmentPlan.cancellation_fee)}</u>
                          </>
                        )}
                        .
                      </>
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col md={15} lg={15} xl={15} span={15} xs={24} sm={24}>
              {installmentPlan.status === "unpaid" && shopifyVariant.inventory_quantity > 0 ? (
                <Elements stripe={stripePromise} options={options}>
                  <Form installmentPlan={installmentPlan} publicPage={publicPage} />
                </Elements>
              ) : (
                <Alert
                  type="error"
                  message={
                    installmentPlan.status !== "unpaid" ? "This payment plan is invalid." : "This product is sold out."
                  }
                />
              )}
            </Col>
          </Row>
        </Content>
      )}
    </Space>
  );
};
