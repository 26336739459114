import { getSessionToken } from "@shopify/app-bridge/utilities";

export const moneyFormat = (price) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(price);
};

export const CEndpoints = {
  getHashedInstallmentPlans: "/payment-forms/:hash/installment_plan",
  payHashInstallmentPlans: "/payment-forms/:hash/pay",
  getInstallmentPlans: "/shopify/installment_plans",
  getProducts: "/shopify/products",
  getProductById: "/shopify/products/:id",
  postInstallmentPlans: "/shopify/installment_plans",
  getInstallmentPlanById: "/shopify/installment_plans/:id",
  payInstallmentPlans: "/shopify/installment_plans/:id/pay"
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const getApi = async (
  endpointPath: keyof typeof CEndpoints,
  queryParams?: Record<any, any>,
  pathParams?: Record<any, any>
) => {
  const append = `?${new URLSearchParams(queryParams)}`;
  const response = await fetch(endpoint(endpointPath, pathParams) + append, {
    method: "GET",
    headers: await getHeaders()
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

export const postApi = async (
  endpointPath: keyof typeof CEndpoints,
  body?: Record<any, any>,
  pathParams?: Record<any, any>
) => {
  const response = await fetch(endpoint(endpointPath, pathParams), {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify(body)
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

export const putApi = async (
  endpointPath: keyof typeof CEndpoints,
  body?: Record<any, any>,
  pathParams?: Record<any, any>
) => {
  const response = await fetch(endpoint(endpointPath, pathParams), {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify(body)
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

export const deleteApi = async (endpointPath: keyof typeof CEndpoints, pathParams: Record<any, any>) => {
  const response = await fetch(endpoint(endpointPath, pathParams), {
    method: "DELETE",
    headers: await getHeaders()
  });
  if (response.redirected) {
    window.location.href = response.url;
  }
  const json = await response.json();
  return json;
};

const endpoint = (endpoint: keyof typeof CEndpoints, pathParams?: Record<string, string>) => {
  let endpointUri = CEndpoints[endpoint];
  if (pathParams) {
    Object.entries(pathParams).forEach(([key, value]) => {
      endpointUri = endpointUri.replace(`:${key}`, value);
    });
  }
  return endpointUri;
};

const getHeaders = async () => {
  // @ts-ignore
  if (window.app) {
    // @ts-ignore
    const sessionToken = await getSessionToken(window.app);
    return {
      // @ts-ignore
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json"
    };
  } else {
    return {
      // @ts-ignore
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
      "Content-Type": "application/json"
    };
  }
};
