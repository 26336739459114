import React from "react";
const { Content } = Layout;
import { Header, PaymentForm } from "@components";
import { Space, Layout } from "antd";

export const PaymentFormPage = () => {
  return (
    <Space direction="vertical" style={{ width: "100%", backgroundColor: "white" }} size={[0, 48]}>
      <Content>
        <Header selected="installment-plans" />
        <PaymentForm publicPage={false} />
      </Content>
    </Space>
  );
};
