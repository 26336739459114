import React from "react";
const { Content } = Layout;
import { PaymentForm } from "@components";
import { Space, Layout } from "antd";

export const PaymentFormPublicPage = () => {
  return (
    <Space direction="vertical" style={{ width: "100%", backgroundColor: "white" }} size={[0, 48]}>
      <Content>
        <PaymentForm publicPage={true} />
      </Content>
    </Space>
  );
};
