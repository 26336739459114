import React, { useEffect, useState } from "react";
import { copyTextToClipboard, getApi, moneyFormat } from "@utils";
import { Table, Space, Layout, Button, Tag } from "antd";
import { Typography } from "antd";
import { Col, Row } from "antd";
import { IInstallmentPlanType } from "@types";
import { Header } from "@components";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
const { Content } = Layout;

import type { ColumnsType, TablePaginationConfig } from "antd/es/table";

const { Title } = Typography;

const columns: ColumnsType<IInstallmentPlanType> = [
  {
    title: "Name",
    dataIndex: "name",
    width: "30%",
    render: (value, record) => {
      return (
        <div>
          {value}
          <Row style={{ marginTop: 10 }}>
            <Col span={2}>
              {record.shopify_product && (
                <img
                  src={record.product_image}
                  width={"80%"}
                  style={{ borderRadius: 5, border: "1px solid #eee", marginRight: 5 }}
                />
              )}
            </Col>
            <Col span={21}>
              <strong>{record.shopify_product.title}</strong>
              <p>{moneyFormat(record.shopify_variant.price)}</p>
            </Col>
          </Row>
        </div>
      );
    }
  },
  {
    title: "Deposit",
    dataIndex: "deposit",
    render: (value) => {
      return moneyFormat(value);
    }
  },
  {
    title: "Cancellation Fee",
    dataIndex: "cancellation_fee",
    render: (value) => {
      return moneyFormat(value);
    }
  },
  {
    title: "Interval",
    dataIndex: "interval",
    render: (_, record) => {
      return `Every ${pluralize(record.interval_type, record.interval_count, record.interval_count !== 1)}`;
    }
  },
  {
    title: "Installment Count",
    dataIndex: "installment_count"
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => {
      return <Tag>{status}</Tag>;
    }
  },
  {
    title: "Action",
    dataIndex: "operation",
    key: "operation",
    render: (_, record) => {
      return (
        record?.status === "unpaid" && (
          <>
            <Button type="link">
              <Link to={`/shopify/payment-form/${record.id}`}>Preview Payment Form</Link>
            </Button>
            <Button
              type="link"
              onClick={() => {
                copyTextToClipboard(window.location.origin + `/payment-forms/${record.secret_token}`);
              }}
            >
              Copy URL
            </Button>
            <Button type="primary">
              <Link target="_blank" to={`/payment-forms/${record.secret_token}`}>
                Open Payment Form
              </Link>
            </Button>
          </>
        )
      );
    }
  }
];

interface TableParams {
  pagination?: TablePaginationConfig;
}

export const InstallmentPlansIndex = (props) => {
  const [data, setData] = useState<IInstallmentPlanType[]>();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      position: ["bottomCenter"],
      current: 1,
      pageSize: 10
    }
  });

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getInstallmentPlans", {
      page: tableParams.pagination.current
    });
    setLoading(false);
    setData(response.installment_plans);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total
      }
    });
  };
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);

  return (
    <Space direction="vertical" style={{ width: "100%", backgroundColor: "white" }} size={[0, 48]}>
      <Content>
        <Header selected="installment-plans" />
        <Space size="middle" direction="vertical" style={{ padding: "0 15px", width: "100%" }}>
          <Typography>
            <Title>Payment Plans</Title>
          </Typography>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            loading={loading}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
          />
        </Space>
      </Content>
    </Space>
  );
};
