import React, { useState, useEffect } from "react";
import { Layout, Space, Spin } from "antd";
import { getApi, moneyFormat, postApi } from "@utils";
import { Button, Checkbox, Form, Input } from "antd";
import { Cascader, InputNumber, Select } from "antd";
import { Typography } from "antd";
import { Col, Row } from "antd";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";
const { Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;

import { IProductType, TInstallmentPlanFieldType, IVariantType } from "@types";

interface IInstallmentPlansFormProps {
  shopifyProductId: string;
  shopifyVariantId: string;
}

export const InstallmentPlansForm = ({ shopifyProductId, shopifyVariantId }: IInstallmentPlansFormProps) => {
  const navigate = useNavigate();
  const [product, setProduct] = useState<IProductType>();
  const [variant, setVariant] = useState<IVariantType>();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [deposit, setDeposit] = useState<string | number>(100);
  const [cancellationFee, setCancellationFee] = useState<string | number>(0);
  const [intervalCount, setIntervalCount] = useState<string | number>(1);
  const [intervalType, setIntervalType] = useState<string | number>("month");
  const [installmentCount, setInstallmentCount] = useState<string | number>(12);
  const [calcInstallmentCount, setCalcInstallmentCount] = useState<string | number>("");
  const [summary, setSummary] = useState("");
  const [recurringPrice, setRecurringPrice] = useState(0.0);

  const parser = (value) => value!.replace(/\$\s?|(,*)/g, "");
  const formatter = (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const intervalTypeAfter = (
    <Select
      defaultValue="month"
      style={{ width: 110 }}
      onChange={(intervalValue) => {
        setIntervalType(intervalValue);
      }}
    >
      <Option value="day">{pluralize("Day", intervalCount)}</Option>
      <Option value="week">{pluralize("Week", intervalCount)}</Option>
      <Option value="month">{pluralize("Month", intervalCount)}</Option>
      <Option value="year">{pluralize("Year", intervalCount)}</Option>
    </Select>
  );
  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getProductById", {}, { id: shopifyProductId });
    setLoading(false);
    setProduct(response.product);
    setVariant(response.product.variants.find((v) => String(v.id) === String(shopifyVariantId)));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = async (values: any) => {
    console.log("Success:", values);
    const response = await postApi("postInstallmentPlans", {
      shopify_product_id: shopifyProductId,
      shopify_variant_id: shopifyVariantId,
      name,
      description,
      deposit,
      cancellation_fee: cancellationFee,
      interval_count: intervalCount,
      interval_type: intervalType,
      installment_count: installmentCount
    });
    navigate(response.redirect);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const totalPrice = parseFloat(variant?.price);
  const fDeposit = parseFloat(String(deposit));
  const fCancellationFee = parseFloat(String(cancellationFee));
  const calcRecurringPrice = (totalPrice - fDeposit) / parseInt(String(installmentCount), 10);

  return (
    <Space direction="vertical" style={{ width: "100%", backgroundColor: "white", padding: "15px" }} size={[0, 48]}>
      <Content>
        <Row>
          <Col span={15}>
            {loading && <Spin />}
            {!loading && (
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 900 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item<TInstallmentPlanFieldType>
                  label="Order Name"
                  name="name"
                  rules={[{ required: true, message: "Please enter an Order Name" }]}
                >
                  <Input placeholder="Order Name" onChange={(e) => setName(e.target.value)} />
                </Form.Item>
                <Form.Item<TInstallmentPlanFieldType>
                  label="Deposit"
                  name="deposit"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return Promise.resolve(!!value && parseFloat(value) > 0);
                      },
                      message: "Please enter a positive value for Deposit."
                    }
                  ]}
                >
                  <InputNumber
                    defaultValue={deposit}
                    formatter={formatter}
                    parser={parser}
                    onChange={(value: number | string) => {
                      setDeposit(value);
                    }}
                  />
                </Form.Item>
                <Form.Item<TInstallmentPlanFieldType>
                  label="Cancellation Fee"
                  name="cancellation_fee"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return Promise.resolve(!value || parseFloat(value) > 0);
                      },
                      message: "Please enter a positive number."
                    }
                  ]}
                >
                  <InputNumber
                    defaultValue={cancellationFee}
                    formatter={formatter}
                    parser={parser}
                    onChange={(value: number | string) => {
                      setCancellationFee(value);
                    }}
                  />
                  <Text type="secondary" style={{ fontSize: 14 }}>
                    &nbsp;(Charged upfront. Refunded only when all funds are collected.)
                  </Text>
                </Form.Item>
                <Form.Item<TInstallmentPlanFieldType>
                  label="Interval"
                  name="interval_type"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return Promise.resolve(!value || parseFloat(value) > 0);
                      },
                      message: "Please enter a positive number."
                    }
                  ]}
                >
                  <InputNumber
                    defaultValue={intervalCount}
                    addonBefore={"Every"}
                    addonAfter={intervalTypeAfter}
                    onChange={(value: number | string) => {
                      setIntervalCount(value);
                    }}
                  />
                </Form.Item>
                <Form.Item<TInstallmentPlanFieldType>
                  label="Installment Count"
                  name="installment_count"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        return Promise.resolve(!value || parseFloat(value) > 0);
                      },
                      message: "Please enter a positive number."
                    }
                  ]}
                >
                  <InputNumber
                    defaultValue={installmentCount}
                    value={installmentCount}
                    onChange={(value: number | string) => {
                      setInstallmentCount(value);
                    }}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Create Payment Plan
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
          <Col span={9}>
            <Row>
              <Col span={4}>
                {product && (
                  <img src={product.photo_url} width={80} style={{ borderRadius: 5, border: "1px solid #eee" }} />
                )}
              </Col>
              <Col span={20}>
                <h3 style={{ marginTop: 0 }}>{product && product.title}</h3>
                <p>{variant && moneyFormat(variant.price)}</p>
                {variant && (
                  <p>
                    {variant.title}. Quantity Available: {variant.inventory_quantity}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={22}>
                <Title level={5} style={{ fontWeight: "normal", marginTop: 0 }}>
                  {variant?.price && (
                    <>
                      The customer will pay a deposit of <strong>{moneyFormat(deposit)}</strong>.
                      {fCancellationFee > 0 && (
                        <>
                          &nbsp;They will pay a cancellation fee of <strong>{moneyFormat(cancellationFee)}</strong>
                        </>
                      )}
                      &nbsp;After which, they will pay <strong>{moneyFormat(calcRecurringPrice)}</strong>{" "}
                      <strong>
                        every&nbsp;
                        {pluralize(intervalType, intervalCount, intervalCount !== 1)}
                      </strong>{" "}
                      for <strong>{installmentCount}</strong> times.
                    </>
                  )}
                </Title>
                <table cellPadding="5">
                  <tbody>
                    <tr>
                      <td>Deposit</td>
                      <td>
                        <strong>{moneyFormat(deposit)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Cancellation Fee</td>
                      <td>
                        <strong>{moneyFormat(cancellationFee)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Amount Due Today</td>
                      <td>
                        <strong>
                          {moneyFormat(parseFloat(String(deposit)) + parseFloat(String(cancellationFee)))}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Installment Plan Interval</td>
                      <td>
                        <strong>
                          Every&nbsp;
                          {pluralize(intervalType, intervalCount, intervalCount !== 1)}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Amount Due{" "}
                        <u>
                          Every&nbsp;
                          {pluralize(intervalType, intervalCount, intervalCount !== 1)}
                        </u>
                      </td>
                      <td>
                        <strong>{moneyFormat(calcRecurringPrice)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Installment count</td>
                      <td>
                        <strong>{installmentCount}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Amount Paid by End of Installment Plan</td>
                      <td>
                        <strong>{moneyFormat(variant?.price)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Space>
  );
};
