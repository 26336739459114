import React, { useEffect, useState } from "react";
import { getApi, moneyFormat } from "@utils";
import { Table, Space, Input } from "antd";
import { Typography } from "antd";
import { IProductType, IVariantType } from "@types";
import { Link } from "react-router-dom";

import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { TableColumnsType } from "antd";

interface TableParams {
  pagination?: TablePaginationConfig;
}

const { Title } = Typography;

const columns: ColumnsType<IProductType> = [
  {
    title: "",
    dataIndex: "photo_url",
    sorter: false,
    render: (photo_url) => {
      return <img src={photo_url} width={60} />;
    },
    width: "10%"
  },
  {
    title: "Title",
    dataIndex: "title",
    render: (title) => {
      return <strong>{title}</strong>;
    }
  }
];

export const ProductsIndex = (props) => {
  const [data, setData] = useState<IProductType[]>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [page, setPage] = useState("");
  const [search, setSearch] = useState("");

  const onPrevPage = () => {
    setPage(prevPage);
  };

  const onNextPage = () => {
    setPage(nextPage);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        // @ts-ignore
        <a onClick={onPrevPage}>Previous</a>
      );
    }
    if (type === "next") {
      return (
        // @ts-ignore
        <a onClick={onNextPage}>Next</a>
      );
    }
  };

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      position: ["bottomCenter"],
      current: 1,
      pageSize: 25,
      total,
      itemRender
    }
  });

  const fetchData = async () => {
    setLoading(true);
    const response = await getApi("getProducts", {
      page: tableParams.pagination.current,
      per_page: tableParams.pagination.pageSize,
      search
    });
    setLoading(false);
    if (response?.data) {
      setData(response.data);
      setTotal(response.count);
    }
  };

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total,
        itemRender
      }
    });
  }, [total]);

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams), search]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const expandedRowRender = (record: any) => {
    const smallData = record.shopify_variants;
    const columns: TableColumnsType<IVariantType> = [
      { title: "Title", dataIndex: "title", key: "title" },
      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        render: (price) => {
          return moneyFormat(price);
        }
      },
      { title: "Qty Available", dataIndex: "inventory_quantity", key: "inventory_quantity" },
      {
        title: "Action",
        dataIndex: "operation",
        key: "operation",
        render: (_, variantRecord) => {
          return variantRecord && record && variantRecord.inventory_quantity > 0 ? (
            <Link type="primary" to={`/shopify/installment-plans/new/${record.shopify_id}/${variantRecord.shopify_id}`}>
              Create Payment Plan
            </Link>
          ) : null;
        }
      }
    ];

    return <Table key={`products-subTable-${record.id}`} columns={columns} dataSource={smallData} pagination={false} />;
  };
  return (
    <Space size="middle" direction="vertical" style={{ padding: "0 15px", width: "100%" }}>
      <Typography>
        <Title>Products</Title>
      </Typography>
      <Input
        size="large"
        allowClear={true}
        defaultValue={search}
        placeholder="Search Products"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onPressEnter={() => {
          fetchData();
        }}
      />
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
          defaultExpandAllRows: true,
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} style={{ fontSize: 20 }} />
            ) : (
              <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} style={{ fontSize: 20 }} />
            )
        }}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Space>
  );
};
